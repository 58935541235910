// import React from 'react'
// // import BlogRoll from '../components/BlogRoll'

// export default class BlogIndexPage extends React.Component {
//   render() {
//     return (
//       <React.Fragment>
//         <h1>Latest post</h1>
//         <section>
//           <div>
//             {/* <BlogRoll /> */}
//           </div>
//         </section>
//       </React.Fragment>
//     )
//   }
// }

// https://blog.logrocket.com/creating-a-gatsby-blog-from-scratch/

import React, { useMemo } from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import PrevIcon from "bootstrap-icons/icons/arrow-left.svg"
import NextIcon from "bootstrap-icons/icons/arrow-right.svg"
import { parseISO } from "date-fns"

import { PostTags } from "../components/PostTags"
import { SEO as Seo } from "../components/seo"
import "../css/blog.css"
import { mediaQueries } from "../config/mediaQueries"
import { Layout } from "../components/layout"

const Container = styled.div`
  width: auto;
  padding: 1rem;
  margin: 0 auto;
  ${mediaQueries("md")`
    width: 670px;
    padding: 1.5rem 0;
  `}
`

// pagination buttons
const darkTextColor = "#545652"

const PageButton = styled.div`
  flex: 1;
  border: 1px solid var(--green-bg-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  &:hover {
    border-color: var(--green-text-color);
  }
  > a {
    flex: 1;
    color: var(--green-text-color);
    text-decoration: none;
    font-size: 1rem;
    padding: 1.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  > a:hover {
    color: ${darkTextColor};
    path: {
      fill: ${darkTextColor};
    }
  }
`

const PageButtonIcon = styled.span`
  padding: 0 0.8rem 0.2rem;
  path {
    fill: var(--green-text-color);
  }
`

const Spacer = styled.div`
  padding: 0.5rem;
`

const EmptyPageButton = styled.div`
  flex: 1;
`

// const DisabledPageButton = styled(PageButton)`
//   color: #9ea2ab;
//   border-color: #f2f6fa;
//   &:hover {
//     border-color: #f2f6fa;
//   }
// `

const NavigatePageButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
`

const NavigatePageSection = styled.div`
  padding: 3rem 0;
`

const PostMetaText = styled.small`
  color: #797979;
  font-size: 1rem;
`

const PostTitleH2 = styled.h2`
  margin: 0.4rem 0 0;
`

const PostListItem = ({ blogContextPath, post }) => {
  let dtStr = ""
  try {
    const dt = parseISO(post.frontmatter.date)
    dtStr = dt.toLocaleDateString()
  } catch (e) {
    console.error(e)
    dtStr = `0000`
  }

  return (
    <article className="blog-item" key={post.id}>
      <PostMetaText>
        {post.frontmatter.author || "admin"}, {dtStr}
      </PostMetaText>
      <Link to={`${blogContextPath}${post.fields.slug}`}>
        <PostTitleH2>{post.frontmatter.title}</PostTitleH2>
      </Link>
      {post.frontmatter.banner ? (
        <div className="post-banner">
          <Img
            className="post-banner__image"
            fluid={{
              ...post.frontmatter.banner.childImageSharp.fluid,
              aspectRatio: 21 / 9,
            }}
          />
        </div>
      ) : null}
      <p className="blog-item__preview">{post.excerpt}</p>
      <PostTags tags={post.frontmatter.tags} />
    </article>
  )
}

const ListTitleH2 = styled.h2`
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #696969;
`

export default function BlogList({ data, pageContext, ...props }) {
  const { posts } = data.blog
  const { siteUrl, lang } = data.site.siteMetadata
  const { blogContextPath, currentPage, numPages, limit } = pageContext

  const createNaviButtons = (curPage, numPage) => {
    if (numPage === 1) {
      // 总页数一页，没有翻页需求
      return <></>
    }
    const iconProps = {
      width: 22,
      height: 22,
      viewBox: "0 0 16 16",
    }
    if (curPage === 1) {
      // 第一页
      return (
        <NavigatePageButtons>
          <EmptyPageButton />
          <Spacer />
          <PageButton title={`更早的 ${limit} 篇文章`}>
            <Link to={`/blog/${curPage + 1}`}>
              <span>更早的</span>
              <PageButtonIcon>
                <NextIcon {...iconProps} />
              </PageButtonIcon>
            </Link>
          </PageButton>
        </NavigatePageButtons>
      )
    } else if (curPage === numPage) {
      // 最后一页
      return (
        <NavigatePageButtons>
          <PageButton title={`更近的 ${limit} 篇文章`}>
            <Link to={`${blogContextPath}/${curPage === 2 ? "" : curPage - 1}`}>
              <PageButtonIcon>
                <PrevIcon {...iconProps} />
              </PageButtonIcon>
              <span>更新的</span>
            </Link>
          </PageButton>
          <Spacer />
          <EmptyPageButton />
          {/* <DisabledPageButton>
            <span>没有更早的文章了</span>
          </DisabledPageButton> */}
        </NavigatePageButtons>
      )
    } else {
      return (
        <NavigatePageButtons>
          <PageButton>
            <Link to={`${blogContextPath}/${curPage === 2 ? "" : curPage - 1}`}>
              <PageButtonIcon>
                <PrevIcon {...iconProps} />
              </PageButtonIcon>
              <span>更新的</span>
            </Link>
          </PageButton>
          <Spacer />
          <PageButton>
            <Link to={`/blog/${curPage + 1}`}>
              <span>更早的</span>
              <PageButtonIcon>
                <NextIcon {...iconProps} />
              </PageButtonIcon>
            </Link>
          </PageButton>
        </NavigatePageButtons>
      )
    }
  }

  const canonical = useMemo(() => {
    if (currentPage === 1) {
      return `${siteUrl}/blog/`
    }
    return `${siteUrl}/blog/${currentPage}`
  }, [siteUrl, currentPage])

  return (
    <Layout>
      <Seo title="blog" lang={lang} />
      <Helmet>
        <link rel="canonical" href={canonical} />
      </Helmet>
      <Container>
        <ListTitleH2>recent posts</ListTitleH2>
        {posts.map(post => (
          <PostListItem
            key={post.id}
            blogContextPath={blogContextPath}
            post={post}
          />
        ))}
        <NavigatePageSection>
          {createNaviButtons(currentPage, numPages)}
        </NavigatePageSection>
      </Container>
    </Layout>
  )
}

BlogList.propTypes = {
  // 分页信息
  pageContext: PropTypes.shape({
    limit: PropTypes.number.isRequired,
    skip: PropTypes.number.isRequired,
    numPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired, // 当前分页， 1-based
  }),
}

export const pageQuery = graphql`
  query blogListQuery0($skip: Int!, $limit: Int!) {
    blog: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: $limit
      skip: $skip
    ) {
      posts: nodes {
        fields {
          slug
        }
        frontmatter {
          date
          title
          author
          banner {
            childImageSharp {
              fluid(maxWidth: 800, toFormat: WEBP) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tags
        }
        excerpt
        id
      }
    }
    site {
      siteMetadata {
        title
        description
        siteUrl
        lang
      }
    }
  }
`
